import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NavParams, ModalController } from '@ionic/angular';
import { lowercaseNoSpaceValidator } from 'src/app/validators/lowercase-no-space.validator';
import { NoWhitespaceValidator } from 'src/app/validators/no-whitespace'

@Component({
  selector: 'app-showinput',
  templateUrl: './showoutput.component.html',
  styleUrls: ['./showoutput.component.scss'],
})
export class ShowOutputComponent implements OnInit {
  outputNo
  outputs
  output
  adamName
  outputForm: FormGroup;

  constructor(private navParams: NavParams, private modalCtrl: ModalController, private fb: FormBuilder) { }

  ngOnInit() {
    this.outputNo = this.navParams.get('i')
    this.output = Object.assign({}, this.navParams.get('output')) // Shallow copy

    console.log('[YamlGenerator] {ShowOutputComponent} output', this.output)

    // const nameParts = this.output['name'].split('_')
    // this.adamName = nameParts[0]

    // if(!this.output['comment']){
    //   this.output['comment'] = this.output['name']
    // }
    const outs = this.navParams.get('outputs')

    this.outputs = []
    for (const key in outs) {
      if (Object.prototype.hasOwnProperty.call(outs, key)) {
        this.outputs.push(outs[key])
      }
    }

    this.outputForm = this.fb.group({
      description: [this.output.description, [Validators.required, lowercaseNoSpaceValidator()]],
      type: [this.output.type, [Validators.required]],
      name: [this.output.name, [Validators.required, NoWhitespaceValidator]]
    });

  }


  saveData(){
    this.output.name = this.outputForm.controls.name.value
    this.output.description = this.outputForm.controls.description.value
    this.output.type = this.outputForm.controls.type.value
    this.modalCtrl.dismiss({out: this.output, outNo: this.outputNo})
  }

  cancel(){
    this.modalCtrl.dismiss()
  }

  showHelp(){
    
  }

}
