import { Component, OnInit, Input } from '@angular/core'
import { NavParams, ModalController } from '@ionic/angular'
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms'

import { NoWhitespaceValidator } from 'src/app/validators/no-whitespace'
import { UtilsComponent } from 'src/app/components/utils/utils.component'

@Component({
  selector: 'app-showxlight',
  templateUrl: './showxlight.component.html',
  styleUrls: ['./showxlight.component.scss'],
})
export class ShowXLightComponent implements OnInit {
  xlight
  outputs

  xLightForm: FormGroup

  constructor(private navParams: NavParams, private modalCtrl: ModalController, public fb: FormBuilder, private utils: UtilsComponent ) { 
    this.xlight = {id:'', description: ''}
    this.xLightForm = this.fb.group({
      XLightId:  [ Validators.required ],
      description: [this.xlight.description]
      // inputName:  [ Validators.required, NoWhitespaceValidator],
    })
  }

  ngOnInit() {
    // get inputs and convert it to an array
    this.xlight = this.navParams.get('xlight')
    this.outputs = this.navParams.get('outputs')

    console.log('[ShowXLight] {ngOnInit} xlight', this.xlight)

    this.setValidations()
  }

  ngAfterViewInit() {
    this.setValidations()
  }

  setValidations(){
    this.xLightForm = this.fb.group({
      description: [this.xlight.description],
      XLightId:  [this.xlight.id, Validators.required ],
    })
  }

  saveData(){
    this.modalCtrl.dismiss(this.xlight)
  }

  delete(){
     this.utils.presentAlertConfirm('Delete', 'Are you sure you want to delete this?').then(
      (res)=>{
        if(res == 'ok'){
          this.modalCtrl.dismiss('delete')
        }
      }
     )
  }

  cancel(){
    this.modalCtrl.dismiss()
  }

}
