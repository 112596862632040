import { Component, OnInit } from '@angular/core';
import { NavParams } from '@ionic/angular';
import { ToastController } from '@ionic/angular';

@Component({
  selector: 'app-text-modal',
  templateUrl: './text-modal.component.html',
  styleUrls: ['./text-modal.component.scss'],
})
export class TextModalComponent implements OnInit {
  text

  constructor(private navParams: NavParams, private toastController:ToastController) { }

  ngOnInit() {
    this.text = this.navParams.get('text')
  }

  async copiedToClipboard($event){
    const toast = await this.toastController.create({
      message: 'Data copied to clipboard',
      duration: 2000
    });
    toast.present();
  }

}
