import { ToolsComponent } from './tools/tools.component'
import { NgModule, CUSTOM_ELEMENTS_SCHEMA  } from '@angular/core'
import { CommonModule, HashLocationStrategy, LocationStrategy } from '@angular/common'
import { BrowserModule } from '@angular/platform-browser'
import { RouteReuseStrategy } from '@angular/router'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { HttpClientModule } from '@angular/common/http'

import { IonicModule, IonicRouteStrategy } from '@ionic/angular'
import { SplashScreen } from '@ionic-native/splash-screen/ngx'
import { StatusBar } from '@ionic-native/status-bar/ngx'

import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic'
import { NgxCsvParserModule } from 'ngx-csv-parser'
import { ZXingScannerModule } from '@zxing/ngx-scanner'
import { NgxKjuaModule } from 'ngx-kjua';

import { AngularFireModule } from '@angular/fire'
import { AngularFireFunctionsModule, ORIGIN, NEW_ORIGIN_BEHAVIOR } from '@angular/fire/functions'
import { USE_EMULATOR as FUNCTIONS_EMULATOR } from '@angular/fire/functions'
import { ngfModule } from 'angular-file'
import { ClipboardModule } from 'ngx-clipboard';
import { InstallButton } from 'esp-web-tools/dist/install-button'

import { PlusMinusComponent } from './components/plus-minus/plus-minus.component'

import { environment } from './../environments/environment'
import { YamlGeneratorComponent } from './tools/yaml-generator/adam-i2c.component'
import { HomepageComponent } from './homepage/homepage.component'
import { TextModalComponent } from './tools/yaml-generator/text-modal/text-modal/text-modal.component'
import { ShowinputComponent } from './tools/yaml-generator/showinput/showinput.component'
import { ShowOutputComponent } from './tools/yaml-generator/showoutput/showoutput.component'
import { UtilsComponent } from './components/utils/utils.component'
import { InputHelpComponent } from './tools/yaml-generator/showinput/help/input-help/input-help.component'
import { AppHelpComponent } from './tools/yaml-generator/help/app-help/app-help.component'
import { Xr8HelpComponent } from './tools/yaml-generator/help/xr8-help/xr8-help.component'
import { from } from 'rxjs'
import { ShowxswitchComponent } from './tools/yaml-generator/showxswitch/showxswitch.component'
import { XswitchesAttentionComponent } from './tools/yaml-generator/help/xswitches-attention/xswitches-attention.component'
import { ShowXLightComponent } from './tools/yaml-generator/showxlight/showxlight.component'



@NgModule({
  declarations: [AppComponent,
    ToolsComponent, PlusMinusComponent, HomepageComponent,
    YamlGeneratorComponent, ShowinputComponent, ShowOutputComponent, ShowxswitchComponent,
    TextModalComponent, InputHelpComponent, AppHelpComponent, Xr8HelpComponent, XswitchesAttentionComponent,
    ShowXLightComponent
  ],
  entryComponents: [
    YamlGeneratorComponent
  ],
  imports: [BrowserModule,
    CommonModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    FormsModule, ReactiveFormsModule,
    AngularFireFunctionsModule,
    HttpClientModule,
    ngfModule,
    NgxCsvParserModule,
    // AngularFireModule.initializeApp(environment.firebase),
    ZXingScannerModule, NgxKjuaModule,
    ClipboardModule
  ],
  providers: [
    StatusBar,
    InstallButton,
    SplashScreen,
    UtilsComponent,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: NEW_ORIGIN_BEHAVIOR, useValue: true },
  //  { provide: ORIGIN, useValue: 'http://iotix-d55f1.web.app' }
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
  bootstrap: [AppComponent]
})
export class AppModule {}
