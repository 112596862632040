import { Component, OnInit, Input, forwardRef } from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";

@Component({
  selector: 'app-plus-minus',
  templateUrl: './plus-minus.component.html',
  styleUrls: ['./plus-minus.component.scss'],
  providers: [{
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PlusMinusComponent),
      multi: true
  }]
})
export class PlusMinusComponent implements ControlValueAccessor  {
  @Input() _value;

  public get value(){
    return this._value;
  }
  
  public set value(v){
    this._value = v;
    this.onChange(this._value);
    this.onTouched();
  }
  
  // Optional
  onSomeEventOccured(newValue){
    this.value = newValue;
  }

  writeValue(obj: any): void {
    // Step 3
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
  }

  onChange: any = () => { };

  onTouched: any = () => { };

  addValue(){
    this._value++
  }
  
  substractValue(){
    this._value--
  }
  
}
