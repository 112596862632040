import { AbstractControl, ValidatorFn } from '@angular/forms';

export function lowercaseNoSpaceValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const regex = /^[a-z_]+$/;
    const value = control.value || '';
    const valid = regex.test(value) && value.indexOf(' ') === -1;
    return valid ? null : { 'lowercaseNoSpace': true };
  };
}
