import { Component, OnInit, Input } from '@angular/core'
import { NavParams, ModalController } from '@ionic/angular'
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms'
import { UtilsComponent } from 'src/app/components/utils/utils.component'
import { InputHelpComponent } from './help/input-help/input-help.component'

import { NoWhitespaceValidator } from 'src/app/validators/no-whitespace'

@Component({
  selector: 'app-showinput',
  templateUrl: './showinput.component.html',
  styleUrls: ['./showinput.component.scss'],
})
export class ShowinputComponent implements OnInit {
  input
  inputs
  inputNo
  outputs
  inputNamePart
  adamName
  extensions
  selectedExtension
  selectedOutputNo

  inputForm: FormGroup

  constructor(private navParams: NavParams, private modalCtrl: ModalController, public fb: FormBuilder, private utils: UtilsComponent ) { 
    this.inputForm = this.fb.group({
      description:    [],
      inputType:  [ Validators.required ],
      inputName:  [ Validators.required, NoWhitespaceValidator],
      output:     [ Validators.required],
      lightName:  [ NoWhitespaceValidator]
    })
    this.selectedExtension = 0
  }

  ngOnInit() {
    // get inputs and convert it to an array
    this.inputs = this.navParams.get('inputs')
    this.extensions = this.navParams.get('extensions')
    this.inputNo = this.navParams.get('inputNo')
    this.selectedExtension = this.inputs[this.inputNo]
    this.input = Object.assign({}, this.inputs[this.inputNo]) // Shallow copy
    const outs = this.navParams.get('outputs')

    this.outputs = []
    for (const key in outs) {
      if (Object.prototype.hasOwnProperty.call(outs, key)) {
        this.outputs.push(outs[key])
      }
    }

    if (!this.input){
      this.input = {}
    }

    this.setValidations()
    
  }

  ngAfterViewInit() {
    this.setValidations()
  }

  setValidations(){
    console.log('{setValidations}')
    if(!this.inputForm.controls.inputType.value){
      this.inputForm.controls['inputType'].setErrors({required: false})
    }

    if (this.input['type'] === 'light'){
      this.inputForm.controls.inputName.setValidators([Validators.required, Validators.minLength(2), NoWhitespaceValidator ])
      if(!this.inputForm.controls.output.value){
        this.inputForm.controls['output'].setErrors({required: false})
      }
      this.inputForm.controls['lightName'].setValidators([Validators.required, Validators.minLength(2), NoWhitespaceValidator ])
      // this.inputForm.updateValueAndValidity()
    }
    if (this.input['type'] === 'cover'){
      this.inputForm.controls['lightName'].clearValidators()
      this.inputForm.controls['inputName'].clearValidators()
      this.inputForm.controls.lightName.updateValueAndValidity()
    }


    // this.inputForm = this.fb.group({
    //   // inputName: [null, Validators.compose([Validators.required, Validators.minLength(2) ])],
    //   lightName: [null, Validators.compose([Validators.required, Validators.minLength(2) ])]
    //   // 'description': [null, Validators.compose([Validators.required, Validators.minLength(5)])],
    //   // 'fee': [null, Validators.compose([Validators.required, NoWhitespaceValidator])],
    //   // 'winnerPercentage': [null, Validators.compose([Validators.required])],
    //   // 'date': [null, Validators.compose([Validators.required])],
    //   // 'selectedQuiz': [null, Validators.compose([Validators.required])]
    // })
  }



  saveData(){
    // console.log('[ShowinputComponent] {saveData} before saving',  this.input)
    // this.input.output = this.selectedOutputNo

    // if (this.input?.type === 'cover'){
    //   const openInputNo = this.input.open_input_no
    //   const closeInputNo = this.input.close_input_no
    //   this.input.open_input.outputType = this.inputs[openInputNo]
    //   this.input.close_input = this.inputs[closeInputNo]
    //   this.input.open_output.type = 'switch'
    //   this.input.close_output.type = 'switch'
    // }
    
    if (this.input?.type === 'light'){
      this.input.outputType = 'output'
    }

    
    // console.log('[ShowinputComponent] {saveData} saving',  this.input)
    this.modalCtrl.dismiss(this.input)
  }

  cancel(){
    this.modalCtrl.dismiss()
  }

  async showHelp(){
    const mod = await this.modalCtrl.create({
      component: InputHelpComponent,
      cssClass: 'w-90 h-90'
    })
    mod.present()
  }

}
