import { Component, OnInit, Input } from '@angular/core'
import { NavParams, ModalController } from '@ionic/angular'
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms'

import { NoWhitespaceValidator } from 'src/app/validators/no-whitespace'
import { UtilsComponent } from 'src/app/components/utils/utils.component'

@Component({
  selector: 'app-showxswitch',
  templateUrl: './showxswitch.component.html',
  styleUrls: ['./showxswitch.component.scss'],
})
export class ShowxswitchComponent implements OnInit {
  xswitch
  outputs

  xswitchForm: FormGroup

  constructor(private navParams: NavParams, private modalCtrl: ModalController, public fb: FormBuilder, private utils: UtilsComponent ) { 
    this.xswitchForm = this.fb.group({
      xswitchId:  [ Validators.required, Validators.pattern("^[0-9]*$") ],
      type:       [ Validators.required],
      // inputName:  [ Validators.required, NoWhitespaceValidator],
      output1:     [Validators.required ],
      output2:     [Validators.required ],
      output3:     [Validators.required ],
      output4:     [Validators.required ]
      // lightName:  [ NoWhitespaceValidator]
    })
  }

  ngOnInit() {
    // get inputs and convert it to an array
    this.xswitch = this.navParams.get('xswitch')
    this.outputs = this.navParams.get('outputs')
    // this.extensions = this.navParams.get('extensions')
    // this.inputNo = this.navParams.get('inputNo')
    // this.selectedExtension = this.inputs[this.inputNo]
    // this.input = Object.assign({}, this.inputs[this.inputNo]) // Shallow copy
    // const outs = this.navParams.get('outputs')

    // this.outputs = []
    // for (const key in outs) {
    //   if (Object.prototype.hasOwnProperty.call(outs, key)) {
    //     this.outputs.push(outs[key])
    //   }
    // }

    // if (!this.input){
    //   this.input = {}
    // }

    this.setValidations()
    
  }

  ngAfterViewInit() {
    this.setValidations()
  }

  setValidations(){
    this.xswitchForm = this.fb.group({
      description:    [],
      xswitchId:  [this.xswitch.id,[ Validators.required, Validators.pattern("^[0-9]*$"), Validators.max(512)] ],
      type:       [ this.xswitch.type, Validators.required],
      output1:    [this.xswitch['channels'][0]['output'], null ],
      output2:    [this.xswitch['channels'][1]['output'], null ],
      output3:    [this.xswitch['channels'][2]['output'], null ],
      output4:    [this.xswitch['channels'][3]['output'], null ],
      // output1:     [this.xswitch['channels'][0]['output'], Validators.required ],
      // output2:     [this.xswitch['channels'][1]['output'], Validators.required ],
      // output3:     [this.xswitch['channels'][2]['output'], Validators.required ],
      // output4:     [this.xswitch['channels'][3]['output'], Validators.required ],
      // lightName:  [ NoWhitespaceValidator]
    })
    // console.log('Form', this.xswitchForm)
    // if(!this.xswitchForm.controls.inputType.value){
    //   this.xswitchForm.controls['inputType'].setErrors({required: false})
    // }

    // if (this.input['type'] === 'light'){
    //   this.xswitchForm.controls.inputName.setValidators([Validators.required, Validators.minLength(2), NoWhitespaceValidator ])
    //   if(!this.xswitchForm.controls.output.value){
    //     this.xswitchForm.controls['output'].setErrors({required: false})
    //   }
    //   this.xswitchForm.controls['lightName'].setValidators([Validators.required, Validators.minLength(2), NoWhitespaceValidator ])
    // }
    // if (this.input['type'] === 'cover'){
    //   this.xswitchForm.controls['lightName'].clearValidators()
    //   this.xswitchForm.controls['inputName'].clearValidators()
    //   this.xswitchForm.controls.lightName.updateValueAndValidity()
    // }
  }

 

  saveData(){
    this.modalCtrl.dismiss(this.xswitch)
  }

  delete(){
     this.utils.presentAlertConfirm('Delete', 'Are you sure you want to delete this?').then(
      (res)=>{
        if(res == 'ok'){
          this.modalCtrl.dismiss('delete')
        }
      }
     )
  }

  clearOption(channelNo){
    delete this.xswitch['channels'][channelNo]['output']
  }

  cancel(){
    this.modalCtrl.dismiss()
  }

}
