
import { ModalController } from '@ionic/angular'
import { ngfModule, ngf } from 'angular-file'
import { Component, OnInit, NgModule, ViewChild, ElementRef, AfterViewChecked, ChangeDetectorRef, Output } from '@angular/core'
import { CommonModule } from '@angular/common'
import { HttpClient, HttpClientModule, HttpRequest, HttpResponse, HttpEvent} from '@angular/common/http'
import { Subscription, Observable } from 'rxjs'
import { ClipboardService } from 'ngx-clipboard'

import { ShowinputComponent } from './showinput/showinput.component'
import { ShowOutputComponent } from './showoutput/showoutput.component'
import { TextModalComponent } from './text-modal/text-modal/text-modal.component'
import { NgxCsvParser } from 'ngx-csv-parser'
import { NgxCSVParserError } from 'ngx-csv-parser'
import { InstallButton } from 'esp-web-tools/dist/install-button'
import { UtilsComponent } from 'src/app/components/utils/utils.component'
import { ShowxswitchComponent } from './showxswitch/showxswitch.component'
import { AppHelpComponent } from './help/app-help/app-help.component'
import { Xr8HelpComponent } from './help/xr8-help/xr8-help.component'
import { XswitchesAttentionComponent } from './help/xswitches-attention/xswitches-attention.component'
import { ShowXLightComponent } from './showxlight/showxlight.component'

@Component({
  selector: 'app-yaml-generator',
  templateUrl: './adam-i2c.component.html',
  styleUrls: ['./adam-i2c.component.scss'],
})
export class YamlGeneratorComponent implements AfterViewChecked {
  postUrl = '...'
  myFormData: FormData // populated by ngfFormData directive
  httpEvent: HttpEvent<{}>
  csvRecords: any[] = []
  general: { wifi: {secrets: false, enabled: true, ssid: any, password: any}, 
            ap: {enabled: false, ssid: '', password: ''}, 
            manualIp: { enabled: false, staticIp: '', gateway: '192.168.0.1', subnet: '255.255.255.0'},
            xSwitches: {enabled: false},
            xLights: {enabled: false}
          }
  inputs = {}
  outputs = {}
  xSwitches = []
  xLights = []
  header = false
  ymlOutput = {output: [], switch: [], binary_sensor: [], cover: [], light: [], text_sensor: [], number: [], globals: []}
  ymlOutputText = ''
  adamNo = 1
  extensionsNo = 0
  extensions = []
  availableOutputs = []
  covers = []
  lights = []
  projectFile
  backgroundWidth
  backgroundHeight
  advancedOptions = false
  errors
  savePath
  loading = false
  compileLog
  showUploadButton = false
  manifestLink =''
  currentDate = new Date()

  @ViewChild('bgContainer', { static: false }) bgContainer: ElementRef

  constructor(public httpClient: HttpClient, private ngxCsvParser: NgxCsvParser, private cd: ChangeDetectorRef, 
              private modalCtrl: ModalController, private utils: UtilsComponent, private clipboard: ClipboardService) {
    this.general = { 
      wifi: {secrets: false, enabled: true, ssid: "", password: ""}, 
      ap: {enabled: false, ssid: '', password: ''}, 
      manualIp: {enabled: false, staticIp: '', gateway: '192.168.0.1', subnet: '255.255.255.0'},
      xSwitches: {enabled: false},
      xLights: {enabled: false}
    }
    this.errors = {
      _count: 2,
      wifipass: {error: true, msg: 'Password needs to be at least 8 characters long' },
      wifiSsid: {error: true, msg: 'SSID has to be at least 2 characters long' }
    }
    this.createBlankOutputs()
    this.createBlankInputs()
  }

  ngOnInit(){
    this.checkIfUserIsNew()
    // this.utils.showAlert('Warning', 'This is currently under development! Do not use the code on real devices! It will be fully functional soon.' )
  }

  checkIfUserIsNew(){
    const recurringUser: string = this.getCookie('adam-seen-help')
    if(!recurringUser){
      this.setCookie('adam-seen-help', 'true', 999)
      this.showHelp()
    }
  }

  getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + (exdays*24*60*60*1000));
    let expires = "expires="+ d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }

  ngAfterViewChecked(): void {
    this.backgroundWidth = this.bgContainer.nativeElement.clientWidth
    this.backgroundHeight = this.bgContainer.nativeElement.clientHeight
    this.cd.detectChanges()
  }

  createBlankOutputs(){
    this.outputs = []
    let adamPorts = []
    for (const i of [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16]) {
      adamPorts.push( {
        name: 'ha' + this.adamNo + '_do_' + i,
        description: 'ha' + this.adamNo + '_do_' + i,
        pin: i - 1,
        no: i,
        extensionName: 'ha_' + this.adamNo + '_hub_out',
        type: 'switch'
       })
    }
    this.outputs[0] = {name: 'Adam ' + this.adamNo, ports: adamPorts}
  }

  createBlankInputs(){
    this.inputs = {}
    for (let i = 1; i <= 16; i++){
      this.inputs[i] = {
                        description: 'ha' + this.adamNo + '_di_' +  i,
                        name: 'ha' + this.adamNo + '_di_' +  i,
                        pin: i - 1,
                        type: 'switch',
                        selectedExtension: 0,
                        output: i-1,
                        timer: 20,
                        inputNo: i
                        }
    }
  }

  unsorted():number // blank method for ngfor keyvalue
  {
    return 0
  } 

  async showInputOptions(inputNo){
    const mod = await this.modalCtrl.create({
      component: ShowinputComponent,
      componentProps: { inputNo: inputNo, outputs: this.outputs, inputs: this.inputs, extensions: this.extensions} })
    mod.present()
    mod.onDidDismiss().then(
      (res) => {
        if (res.data){
          console.log('{showInputOptions} result', res)
          switch (res.data['type']) {
            case 'cover':
              let openData = Object.assign({}, res.data) // shallow copy
              let closeData = Object.assign({}, res.data) // shallow copy
              openData['pin'] = this.inputs[openData.open_input_no]['pin']
              openData['name'] = this.inputs[openData.open_input_no]['name']
              openData['description'] = this.inputs[openData.open_input_no]['description']
              this.inputs[openData.open_input_no] = openData

              closeData['pin'] = this.inputs[closeData.close_input_no]['pin']
              closeData['name'] = this.inputs[closeData.close_input_no]['name']
              closeData['description'] = this.inputs[closeData.close_input_no]['description']
              closeData['doNotRender'] = true
              this.inputs[closeData.close_input_no] = closeData
            break
            
            case 'light':
              const inp = res.data
              this.inputs[inputNo] = inp
              this.outputs[inp.selectedExtension]['ports'][inp.output]['type'] = 'BinaryOutput'
            break
          
            default:
              this.inputs[inputNo] = res.data
            break
          }
          console.log('{showInputOptions} inputs', this.inputs)
        }
      }
    )
  }

  async showOutputOptions(out, extensionNo, outNo){
    const mod = await this.modalCtrl.create({
      component: ShowOutputComponent,
      componentProps: { 'output': out, i: outNo, outputs: this.availableOutputs} })
    mod.present()
    mod.onDidDismiss().then(
      (res) => {
        this.outputs[extensionNo]['ports'][outNo] = res.data.out
      }
    )
  }

  changeAdamNo(){
    console.log('[changeAdamNo], outputs', this.outputs)
    this.outputs[0].ports.forEach(
      (out, i) => {
        out['extensionName'] = 'ha_' + this.adamNo + '_hub_out'
    });
    console.log('[changeAdamNo], outputs AFTER', this.outputs)

    // for (let key in this.outputs) {
    //   if (this.outputs.hasOwnProperty(key)) {
    //     const nameParts = this.outputs[key]['name'].split('_')
    //     const adamName = nameParts[0]
    //     const newName = 'ha' + this.adamNo + '_' + nameParts[1] + '_' + nameParts[2]
    //     this.outputs[key]['name'] = newName
    //     this.outputs[key]['extensionName'] = 'ha_' + this.adamNo + '_hub_out'
    //   }
    // }
  }

  addExtension(){
    if (this.extensionsNo === 8){
      return
    }

    this.extensionsNo++

    let extPorts = []
    for (let e = 1; e <= 8; e++) {
      extPorts.push({
        name: 'r' + this.extensionsNo + '_k' + e,
        description: 'r' + this.extensionsNo + '_k' + e,
        extensionName: 'xr8_' + this.extensionsNo,
        pin: e - 1, 
        type: 'switch'
      })
    }
    this.outputs[this.extensionsNo] = {
      name: 'xr8_' + this.extensionsNo, 
      ports: extPorts,
      address: '0x2' + (this.extensionsNo - 1)
    }
  }

  removeExtension(){
    if (this.extensionsNo > 0) {
      delete this.outputs[this.extensionsNo]
      this.extensionsNo-- 
    }
  }

  fileUploaded(files){
    console.log('Files uploaded', files)
    // Parse the file you want to select for the operation along with the configuration
    this.ngxCsvParser.parse(files[0], { header: this.header, delimiter: ',' })
      .pipe().subscribe((result: Array<any>) => {

        console.log('Result', result);
        this.csvRecords = result;
        this.createInputs()
        this.parseCsv(result)
      }, (error: NgxCSVParserError) => {
        console.log('Error', error);
      })

  }

  saveProject(){
    const data = {
      adamNo : this.adamNo,
      general: this.general,
      inputs: this.inputs,
      outputs: this.outputs,
      xSwitches: this.xSwitches,
      xLights: this.xLights
    }

    console.log('[YAml generator] {saveProject} data', data)

    const dlink: HTMLAnchorElement = document.createElement('a');
    dlink.download = 'adam' + this.adamNo + '.json' // the file name
    const fileContent = JSON.stringify(data);
    dlink.href = 'data:text/plain;charset=utf-16,' + encodeURIComponent(fileContent);
    dlink.click(); // this will trigger the dialog window
    dlink.remove();
    
  }

  loadProject(files){
    let _self = this
    console.log('Project file', files)
    let reader = new FileReader();
    reader.readAsText(files[0], "UTF-8");
    reader.onload = function (evt) {
      const prjData = JSON.parse( evt.target.result.toString() )
      _self.adamNo = prjData['adamNo']
      _self.general = prjData['general']
      _self.inputs = prjData['inputs']
      _self.outputs = prjData['outputs']
      _self.xSwitches = prjData['xSwitches']
      _self.xLights = prjData['xLights']
      _self.extensionsNo = prjData['outputs'].length - 1
      _self.checkValidation()
      _self.utils.presentToast('Project loaded successfully')
    }
    reader.onerror = (evt) => {
        console.log('error reading file', evt)
        _self.utils.showAlert('Error', 'Error reading project file')
    }
  }

  createInputs(){
    this.csvRecords.forEach(
      (el, i)=>{
        if ( el[3] ){
          const inp = el[1].split('_')
          this.inputs[i] = {
                            comment: el[0],
                            name: 'ha' + this.adamNo + '_di_' +  inp[2],
                            type: el[4],
                            outName: el[3],
                            outComment: el[2],
                            timer: 20,
                            inputNo: i
                           }
        }
      }
    )
  }

  async generateYaml(){
    this.ymlOutput = { output: [], switch: [], binary_sensor: [], cover: [], light: [], text_sensor: [], number: [], globals: []}

    // this.gatherCovers()
    // if ( this.covers.length > 0){
    //   for (const key in this.covers) {
    //     if (Object.prototype.hasOwnProperty.call(this.covers, key)) {
    //       this.ymlOutput['cover'].push(this.generateCoverYaml(this.covers[key]))
    //     }
    //   }
    // }
    
    for (const key in this.inputs) {
      if (Object.prototype.hasOwnProperty.call(this.inputs, key)) {
        let inp = this.inputs[key]
        switch (inp['type']) {
          case 'binary':
            //TODO: change this for more specific binary cases
            this.ymlOutput['binary_sensor'].push(this.generateSwitchInputYaml(inp))
            break
          case 'switch':
            this.ymlOutput['binary_sensor'].push(this.generateSwitchInputYaml(inp))
            // this.ymlOutput['switch'].push(this.generateOutputYaml(inp.selectedExtension, inp.output))
            break
          case 'light':
            // this.ymlOutput['output'].push(this.generateLightOutputYaml(inp))
            this.ymlOutput['light'].push(this.generateLightYaml(inp))
            this.ymlOutput['binary_sensor'].push(this.generateLightInputYaml(inp))
            break
          case 'cover':
            if (!inp['doNotRender']){
              // this.ymlOutput['switch'].push(this.generateCoverOutputYaml(inp))
              this.ymlOutput['cover'].push(this.generateCoverYaml(inp))
              this.ymlOutput['binary_sensor'].push(this.generateCoverInputYaml(inp))
            }
            break
          default:
            break
          }
      }
    }

    for (const ext in this.outputs){
      if (this.outputs.hasOwnProperty(ext)) {
        let extension = this.outputs[ext]
        // console.log('{generateYaml} ext', ext)
        // console.log('{generateYaml} extension', extension)
        for (const key in extension['ports']) {
          if (Object.prototype.hasOwnProperty.call(extension['ports'], key)) {
            const out = extension['ports'][key]
            // console.log('{generateYaml} extension port', out)
            switch (out.type) {
              case 'switch':
                this.ymlOutput['switch'].push(this.generateSwitchOutputYaml(ext, out))
                break;
              case 'BinaryOutput':
                this.ymlOutput['output'].push(this.generateBinaryOutputYaml(ext, out))
                break;
            
              default:
                break;
            }
          }
        }
      }
    }

    if (this.xSwitches.length || this.xLights.length) {
      this.ymlOutput['text_sensor'].push(this.generateUartSensorYaml())
      // generic read-write switch, to activate uart reading
      this.ymlOutput['switch'].push('\n');
      this.ymlOutput['switch'].push('  - platform: gpio\n');
      this.ymlOutput['switch'].push('    pin: GPIO4\n');
      this.ymlOutput['switch'].push('    id: "uart_read_or_write"\n');
      this.ymlOutput['switch'].push('    name: "uart-read/write"\n');
    }
    console.log('---- xSwitches ---- ', this.xSwitches)
    for (const xswitch in this.xSwitches){
      if (this.xSwitches.hasOwnProperty(xswitch)) {
        // console.log('---- xSwitch ---- ', this.xSwitches[xswitch])
        this.ymlOutput['binary_sensor'].push(this.generateXswitchYaml(this.xSwitches[xswitch]))
      }
    }

    console.log('---- xLights ---- ', this.xLights)
    for (const xlight in this.xLights){
      if (this.xLights.hasOwnProperty(xlight)) {
        // console.log('---- xSwitch ---- ', this.xSwitches[xswitch])
        this.ymlOutput['number'].push(this.generateXLightSliderYaml(this.xLights[xlight],1))
        this.ymlOutput['number'].push(this.generateXLightSliderYaml(this.xLights[xlight],2))
        this.ymlOutput['number'].push(this.generateXLightSliderYaml(this.xLights[xlight],3))
        this.ymlOutput['number'].push(this.generateXLightSliderYaml(this.xLights[xlight],4))
        this.ymlOutput['globals'].push(this.generateXLightGlobalsYaml(this.xLights[xlight]))
      }
    }

    this.ymlOutputText = ''
    this.generateGeneralInfo()
    for (const key in this.ymlOutput) {
      if (Object.prototype.hasOwnProperty.call(this.ymlOutput, key)) {
        const element = this.ymlOutput[key]
        if (element.length){
          this.ymlOutputText += '\n' + key + ':'
          element.forEach(
            el => {
              this.ymlOutputText +=  el 
          })
          
        }
      }
    }
  }

  generateGeneralInfo(){
    this.ymlOutputText +=  'esphome:' + '\n'
    this.ymlOutputText +=  '  name: adam-ha' + this.adamNo + '\n'
  if (this.xSwitches.length) {
    this.ymlOutputText +=  '  includes:' + '\n'
    this.ymlOutputText +=  '  - uart_read_line_sensor.h' + '\n'
  }
    this.ymlOutputText +=  '  platform: ESP32' +  '\n'
    this.ymlOutputText +=  '  board: esp-wrover-kit' + '\n'
    this.savePath = 'builds/' + Date.now() + '_adam-ha' + this.adamNo
    this.ymlOutputText +=  '  build_path: ' + this.savePath + '\n \n'

    if (this.general.wifi.enabled){
      this.ymlOutputText +=  'wifi:' + '\n'
      this.ymlOutputText +=  '  ssid: ' + this.general.wifi.ssid + '\n'
      this.ymlOutputText +=  '  password: ' + this.general.wifi.password +  '\n \n'
    }

    if (this.general.ap.enabled){
      this.ymlOutputText +=  '  ap:' + '\n'
      this.ymlOutputText +=  '    ssid: ' + this.general.ap.ssid + '\n'
      this.ymlOutputText +=  '    password: ' + this.general.ap.password +  '\n'
      this.ymlOutputText +=  'captive_portal: ' + '\n \n'
    }

    this.ymlOutputText +=  'logger:' + '\n'
    this.ymlOutputText +=  '  level: DEBUG ' + '\n\n'

    this.ymlOutputText +=  'api:' + '\n'
    this.ymlOutputText +=  '\n'
    this.ymlOutputText +=  'ota:' + '\n\n'
    this.ymlOutputText +=  '  platform: esphome' + '\n\n'

    if (this.general.manualIp.enabled){
      this.ymlOutputText +=  'manual_ip:' + '\n'
      this.ymlOutputText +=  '  static_ip: ' + this.general.manualIp.staticIp + '\n'
      this.ymlOutputText +=  '  gateway: ' + this.general.manualIp.gateway +  '\n'
      this.ymlOutputText +=  '  subnet: ' + this.general.manualIp.subnet +  '\n \n'
    }

    this.ymlOutputText +=  'i2c:' + '\n'
    this.ymlOutputText +=  '  sda: GPIO21' + '\n'
    this.ymlOutputText +=  '  scl: GPIO22' +  '\n'
    this.ymlOutputText +=  '  scan: True' + '\n'
    this.ymlOutputText +=  '  frequency: 100kHz' + '\n \n'

    this.ymlOutputText +=  'pcf8574:' + '\n'
    this.ymlOutputText +=  '  - id: "ha_' + this.adamNo + '_hub_out"' + '\n'
    this.ymlOutputText +=  '    address: 0x26' +  '\n'
    this.ymlOutputText +=  '    pcf8575: True' + '\n'
    this.ymlOutputText +=  '  - id: "ha_' + this.adamNo + '_hub_in"' + '\n'
    this.ymlOutputText +=  '    address: 0x27' +  '\n'
    this.ymlOutputText +=  '    pcf8575: True' + '\n'
    for (let i = 1; i <= this.extensionsNo; i++) {
      this.ymlOutputText +=  '  - id: ' + this.outputs[i]['name'] + '\n'
      this.ymlOutputText +=  '    address: ' + this.outputs[i]['address'] +  '\n'
    }

    if ( this.xSwitches.length ){
      this.ymlOutputText +=  'uart:' + '\n'
      this.ymlOutputText +=  '  id: uart_bus' + '\n'
      this.ymlOutputText +=  '  tx_pin: GPIO16' +  '\n'
      this.ymlOutputText +=  '  rx_pin: GPIO17' + '\n'
      this.ymlOutputText +=  '  baud_rate: 19200' + '\n'
      this.ymlOutputText +=  '  debug:' +  '\n'
      this.ymlOutputText +=  '    direction: BOTH' + '\n'
      this.ymlOutputText +=  '    after:' + '\n'
      this.ymlOutputText +=  '      delimiter: "\\r"' + '\n'
      this.ymlOutputText +=  '    sequence:' + '\n'
      this.ymlOutputText +=  '      - lambda: |-' + '\n'
      this.ymlOutputText +=  '          UARTDebug::log_string(direction, bytes);' + '\n'
    }
  }

  // BINARY
  generateBinaryInputYaml(inp){
    // console.log('{generateSwitchInputYaml} inp', inp)
    // console.log('{generateSwitchInputYaml} output', inp)
    const output = this.outputs[inp.selectedExtension][inp.output]
    let yml = '\n' + '  # ' + inp['comment'].replace('\n', ' | ') + '\n'
    yml += '  - platform: gpio' + '\n'
    // input
    let inpArray = inp['name'].split('_')
    yml += '    name: "' + inp['name'] + '"' +  '\n'
    yml += '    id: "' + inp['name'] + '"' +  '\n'
    yml += '    pin: ' + '\n'
    yml += '      pcf8574: ha' + this.adamNo + '_hub_in ' +  '\n'
    yml += '      number: ' + inp.pin +  '\n'
    yml += '      mode: INPUT ' +  '\n'
    yml += '      inverted: True ' +  '\n'
    return yml
  }
  // BINARY


  // Lights
  generateLightYaml(inp){
    console.log('{generateLightYaml} input', inp)
    const output = this.outputs[inp.selectedExtension]['ports'][inp.output]
    console.log('{generateLightYaml} output', output)
    console.log('{generateLightYaml} outputs', this.outputs)
    let yml = '\n' + '  # ' + inp['description'].replace('\n', ' | ') + '\n'
    yml += '  - platform: binary' + '\n'
    let inpArray = inp['name'].split('_')
    yml += '    name: "' + inp['lightName'] + '"' +  '\n'
    yml += '    id: "' + inp['lightName'] + '"' +  '\n'
    yml += '    output: ' + output['name'] + ' # ' + output['description'] +  '\n'
    return yml
  }

  generateLightInputYaml(inp){
    let yml = '\n' + '  # ' + inp['description'].replace('\n', ' | ') + '\n'
    yml += '  - platform: gpio' + '\n'
    let inpArray = inp['name'].split('_') // input
    yml += '    name: "' + inp['name'] + '"' +  '\n'
    yml += '    id: "' + inp['name'] + '"' +  '\n'
    yml += '    pin: ' + '\n'
    yml += '      pcf8574: ha_' + this.adamNo + '_hub_in ' +  '\n'
    yml += '      number: ' + (inpArray[2] - 1) +  '\n'
    yml += '      mode: INPUT ' +  '\n'
    yml += '      inverted: True ' +  '\n'
    yml += '    on_state: ' +  '\n'       // on_press, on click
    yml += '      then: ' +  '\n'
    yml += '        - light.toggle: ' + inp['lightName'] + ' # ' + inp['outComment'] +  '\n'
    return yml
  }

  generateLightOutputYaml(inp){
    let yml = '\n' + '  # ' + inp['description'].replace('\n', ' | ') + '\n'
    yml += '  - platform: gpio' + '\n'
    yml += '    id: "' + inp['output']['name'] + '"' +  '\n'
    yml += '    pin: ' + '\n'
    yml += '      pcf8574: ' + inp['output']['extensionName'] +  '\n'
    yml += '      number: ' + inp['output']['pin'] +  '\n'
    yml += '      mode: OUTPUT ' +  '\n'
    yml += '      inverted: True ' +  '\n'
    return yml
  }
  // Lights


  // Switches
  generateSwitchInputYaml(inp){
    // console.log('{generateSwitchInputYaml} inp', inp)
    const output = this.outputs[inp.selectedExtension]['ports'][inp.output]
    let yml = '\n' + '  # ' + inp['description'].replace('\n', ' | ') + '\n'
    yml += '  - platform: gpio' + '\n'
    // input
    let inpArray = inp['name'].split('_')
    // console.log('inp', inp)
    yml += '    name: "' + inp['name'] + '"' +  '\n'
    yml += '    id: "' + inp['name'] + '"' +  '\n'
    yml += '    pin: ' + '\n'
    yml += '      pcf8574: ha_' + this.adamNo + '_hub_in ' +  '\n'
    yml += '      number: ' + (inpArray[2] - 1) +  '\n'
    yml += '      mode: INPUT ' +  '\n'
    yml += '      inverted: True ' +  '\n'
    yml += '    on_press: ' +  '\n'       // on_press, on click
    yml += '      then: ' +  '\n'
    yml += '        - switch.toggle: ' + output['name'] + ' # ' + output['outComment'] +  '\n'
    return yml
  }

  generateSwitchOutputYaml(extensionNo, out){
    console.log ('[generateSwitchOutputYaml] out', out)
    let yml = '\n' 
    if(out.description){
      yml += '  # ' + out['description'].replace('\n', ' | ') + '\n'
    }
    yml += '  - platform: gpio' + '\n'
    yml += '    id: "' + out['name'] + '"' +  '\n'
    yml += '    name: "' + out['description'] + '"' +  '\n'
    yml += '    pin: ' + '\n'
    yml += '      pcf8574: ' + out['extensionName'] +  '\n'
    yml += '      number: ' + out['pin'] +  '\n'
    yml += '      mode: OUTPUT ' +  '\n'
    yml += '      inverted: True ' +  '\n'
    return yml
  }

  generateBinaryOutputYaml(extensionNo, out){
    let yml = '\n' 
    if(out.description){
      yml += '  # ' + out['description'].replace('\n', ' | ') + '\n'
    }
    yml += '  - platform: gpio' + '\n'
    yml += '    id: "' + out['name'] + '"' +  '\n'
    yml += '    pin: ' + '\n'
    yml += '      pcf8574: ' + out['extensionName'] +  '\n'
    yml += '      number: ' + out['pin'] +  '\n'
    yml += '      mode: OUTPUT ' +  '\n'
    yml += '      inverted: True ' +  '\n'
    return yml
  }
  // Switches

  // gatherCovers(){
  //   this.covers = []
  //   for (const key in this.inputs) {
  //     if (Object.prototype.hasOwnProperty.call(this.inputs, key)) {
  //       let inp = this.inputs[key]
  //       if(inp['type'] === 'jaluzea' && inp['coverName']){
  //         if(!this.covers[inp['coverName']]){
  //           this.covers[inp['coverName']] = {}
  //         }
  //         this.covers[inp['coverName']][inp.coverAction] = inp
  //       }
  //     }
  //   }
  //   console.log('[gatherCovers] covers ', this.covers)
  // }

  generateCoverInputYaml(inp){
    let openInput = this.inputs[inp['open_input_no']]
    let closeInput = this.inputs[inp['close_input_no']]
    let openOutput = this.outputs[inp['open_output_device']]['ports'][inp['open_output_port']]
    let closeOutput = this.outputs[inp['close_output_device']]['ports'][inp['close_output_port']]

    let yml = '\n' + '  # OPEN ' + inp['description'].replace('\n', ' | ') + '\n'
    yml += '  - platform: gpio' + '\n'
    // input
    yml += '    name: "' + openInput['name'] + '"' +  '\n'
    yml += '    id: "' + openInput['name'] + '"' +  '\n'
    yml += '    pin: ' + '\n'
    yml += '      pcf8574: ha_' + this.adamNo + '_hub_in ' +  '\n'
    yml += '      number: ' + openInput['pin'] +  '\n'
    yml += '      mode: INPUT ' +  '\n'
    yml += '      inverted: True ' +  '\n'
    yml += '    on_press: ' +  '\n'       // on_press, on click
    yml += '      then: ' +  '\n'
    yml += '        - lambda: | ' + '\n'
    yml += '            if (id(' + openInput.coverName + ').current_operation == COVER_OPERATION_IDLE)' + '\n'
    yml += '              {' + '\n'
    yml += '                id(' + openInput.coverName + ').open();' + '\n'
    yml += '              }' + '\n'
    yml += '            else' + '\n'
    yml += '              {' + '\n'
    yml += '                id(' + openInput.coverName + ').stop();' + '\n'
    yml += '              }' + '\n'

    yml += '\n' + '  # CLOSE ' + closeInput['description'].replace('\n', ' | ') + '\n'
    yml += '  - platform: gpio' + '\n'
    yml += '    name: "' + closeInput['name'] + '"' +  '\n'
    yml += '    id: "' + closeInput['name'] + '"' +  '\n'
    yml += '    pin: ' + '\n'
    yml += '      pcf8574: ha_' + this.adamNo + '_hub_in ' +  '\n'
    yml += '      number: ' + closeInput['pin'] +  '\n'
    yml += '      mode: INPUT ' +  '\n'
    yml += '      inverted: True ' +  '\n'
    yml += '    on_press: ' +  '\n'       // on_press, on click
    yml += '      then: ' +  '\n'
    yml += '        - lambda: | ' + '\n'
    yml += '            if (id(' + openInput.coverName + ').current_operation == COVER_OPERATION_IDLE)' + '\n'
    yml += '              {' + '\n'
    yml += '                id(' + openInput.coverName + ').close();' + '\n'
    yml += '              }' + '\n'
    yml += '            else' + '\n'
    yml += '              {' + '\n'
    yml += '                id(' + openInput.coverName + ').stop();' + '\n'
    yml += '              }' + '\n'

    return yml
  }

  generateCoverOutputYaml(inp){
    const randomString = (Math.random() * 1e32).toString(36)

    let yml = '\n' + '  # OPEN ' + inp['description'].replace('\n', ' | ') + '\n'
    yml += '  - platform: gpio' + '\n'
    yml += '    name: '+ inp['open_output']['name'] + '\n'
    yml += '    id: "' + inp['open_output']['name'] + '"' +  '\n'
    yml += '    pin: ' + '\n'
    yml += '      pcf8574: ' + inp['open_output']['extensionName'] +  '\n'
    yml += '      number: ' + inp['open_output']['pin'] +  '\n'
    yml += '      mode: OUTPUT ' +  '\n'
    yml += '      inverted: True ' +  '\n'
    yml += '    interlock: &group_' + randomString + ' [' + inp['open_output']['name'] + ', ' + inp['close_output']['name'] + '] # Use interlocking to keep at most one of the two directions on\n'
    yml += '    restore_mode: always off # If ESP reboots, do not attempt to restore switch state\n'

    yml += '\n' + '  # CLOSE ' + inp['description'].replace('\n', ' | ') + '\n'
    yml += '  - platform: gpio' + '\n'
    yml += '    name: '+ inp['close_output']['name'] + '\n'
    yml += '    id: "' + inp['close_output']['name'] + '"' +  '\n'
    yml += '    pin: ' + '\n'
    yml += '      pcf8574: ' + inp['close_output']['extensionName'] +  '\n'
    yml += '      number: ' + inp['close_output']['pin'] +  '\n'
    yml += '      mode: OUTPUT ' +  '\n'
    yml += '      inverted: True ' +  '\n'
    yml += '    interlock: *group_' + randomString + ' # Use interlocking to keep at most one of the two directions on\n'
    yml += '    restore_mode: always off # If ESP reboots, do not attempt to restore switch state\n'

    return yml
  }

  generateCoverYaml(cover){
    let openInput = this.inputs[cover['open_input_no']]
    let closeInput = this.inputs[cover['close_input_no']]
    let openOutput = this.outputs[cover['open_output_device']]['ports'][cover['open_output_port']]
    let closeOutput = this.outputs[cover['close_output_device']]['ports'][cover['close_output_port']]

    console.log('[YamlGenerator Component] {generateCoverYaml} cover', cover)
    console.log('[YamlGenerator Component] {generateCoverYaml} openOutput', openOutput)

    let yml = '\n' + '  # ' + cover['description'].replace('\n', ' | ') + '\n'
    yml += '  - platform: time_based' + '\n'
    yml += '    name: "' + cover.coverName + '"' +  '\n'
    yml += '    id: ' + cover.coverName +  '\n'
    yml += '    open_action:' + '\n'
    yml += '      - switch.turn_on: ' + openOutput.name + '\n'
    yml += '    open_duration: ' + cover.open_timer + 'sec' + '\n'
    yml += '    close_action:' + '\n'
    yml += '      - switch.turn_on: ' + closeOutput.name + '\n'
    yml += '    close_duration: ' + cover.close_timer + 'sec' + '\n'
    yml += '    stop_action: ' + '\n'
    yml += '      - switch.turn_off: ' + closeOutput.name  + '\n'
    yml += '      - switch.turn_off: ' + openOutput.name + '\n'

    return yml
    console.log('generateCoverInputYaml', yml)
  }

  addXSwitch(){
    const temp ={
      id: '',
      type: 'autorepeat',
      description: '',
      channels: [
        {
          selectedExtension: 0,
          description: '',
          output: ''
        },
        {
          selectedExtension: 0,
          description: '',
          output: ''
        },
        {
          selectedExtension: 0,
          description: '',
          output: ''
        },
        {
          selectedExtension: 0,
          description: '',
          output: ''
        }
      ]
    }
    this.xSwitches.push(temp)
  }

  async editXSwitch(i){
    const mod = await this.modalCtrl.create({
      component: ShowxswitchComponent,
      componentProps: { 'xswitch': this.xSwitches[i], outputs: this.outputs} })
    mod.present()
    mod.onDidDismiss().then(
      (res) => {
        console.log('{editXSwitch} Got data', res.data)
        if( res.data == 'delete'){
          this.xSwitches.splice(i, 1)
          return
        }
        if(res.data){
          this.xSwitches[i] = res.data
        }
      }
    )
  }

  addXLight(){
    const temp ={
      id: '',
      description: '',
      channels: [
        {
          selectedExtension: 0,
          description: '',
          output: ''
        },
        {
          selectedExtension: 0,
          description: '',
          output: ''
        },
        {
          selectedExtension: 0,
          description: '',
          output: ''
        },
        {
          selectedExtension: 0,
          description: '',
          output: ''
        }
      ]
    }
    this.xLights.push(temp)
  }

  async editXLight(i){
    const mod = await this.modalCtrl.create({
      component: ShowXLightComponent,
      componentProps: { 'xlight': this.xLights[i], outputs: this.outputs} })
    mod.present()
    mod.onDidDismiss().then(
      (res) => {
        console.log('{editXLight} Got data', res.data)
        if( res.data == 'delete'){
          this.xLights.splice(i, 1)
          return
        }
        if(res.data){
          this.xLights[i] = res.data
        }
      }
    )
  }

  generateUartSensorYaml(){
    // create text sensor
    let yml = '\n'  + '#  this text_sensor component is the one that actually reads the UART message coming from XSwitch and stores the received string  in id(uart_readline).state' + '\n'
    // yml += 'text_sensor:' + '\n'
    yml += '- platform: custom' +  '\n'
    yml += '  lambda: |-' +  '\n'
    yml += '    auto my_custom_sensor = new UartReadLineSensor(id(uart_bus));' + '\n'
    yml += '    App.register_component(my_custom_sensor);' + '\n'
    yml += '    return {my_custom_sensor};' +  '\n'
    yml += '  text_sensors:' + '\n'
    yml += '    id: "uart_readline"' + '\n'
    return yml
  }

  generateXswitchYaml(xswitch){
    const bits = [16, 32, 64, 128]
    // create text sensor
    let yml = '\n'
    for (let i = 1; i <= 4; i++) {
        yml += '  # xswitch_' + xswitch.id + '_canal_' + i + '\n'
        yml += '  - platform: template' +  '\n'
        yml += '    name: "xswitch_' + xswitch.id + '_canal_' + i + '"' +  '\n'
        yml += '    id: "sensor_' + xswitch.id + '_canal_' + i + '"' + '\n'
        yml += '    lambda: |-' + '\n'
        yml += '      int state = 0;' +  '\n'
        yml += '      for(int i = 3; i < id(uart_readline).state.size(); i++){' + '\n'
        yml += "        state = state * 10 + id(uart_readline).state[i] - '0';" + '\n'
        yml += '      }' + '\n'
        yml += '      if (id(uart_readline).state.substr(0, 2) == "' + xswitch.id + '"' + '\n'
        yml += '      &&  (state / ' + bits[i-1] + ') % 2 == 1) {' + '\n'
        yml += '        return true;' + '\n'
        yml += '      } else if(id(uart_readline).state.substr(0, 2) == "' + xswitch.id + '"' + '\n'
        yml += '      &&  (state / ' + bits[i-1] + ') % 2 == 0) {' + '\n'
        yml += '        return false;' + '\n'
        yml += '      } else {' + '\n'
        yml += '        return {};' + '\n'
        yml += '      }' + '\n'
      if ( xswitch['channels'][i - 1]['output'] ){
        let out = xswitch['channels'][i - 1]
        yml += '    on_click:' + '\n'
        yml += '      - switch.toggle: ' + this.outputs[out.selectedExtension]['ports'][out.output]['name'] + '\n'
      }
        yml += '\n'
    }

    return yml
  }

  generateXLightGlobalsYaml(xlight){
    let yml = '\n'
    yml += '  # dimming_state_xlight_' + xlight.id + '_channel_1\n'
    yml += '  - id: "dimming_state_xlight_' + xlight.id + '_channel_1"\n'
    yml += '    type: int' + '\n'
    yml += '    restore_value: no' + '\n'
    yml += '    initial_value: "0"' + '\n'
    yml += '\n'
    yml += '  # dimming_state_xlight_' + xlight.id + '_channel_2\n'
    yml += '  - id: "dimming_state_xlight_' + xlight.id + '_channel_2"\n'
    yml += '    type: int' + '\n'
    yml += '    restore_value: no' + '\n'
    yml += '    initial_value: "0"' + '\n'
    yml += '\n'
    yml += '  # dimming_state_xlight_' + xlight.id + '_channel_3\n'
    yml += '  - id: "dimming_state_xlight_' + xlight.id + '_channel_3"\n'
    yml += '    type: int' + '\n'
    yml += '    restore_value: no' + '\n'
    yml += '    initial_value: "0"' + '\n'
    yml += '\n'
    yml += '  # dimming_state_xlight_' + xlight.id + '_channel_4\n'
    yml += '  - id: "dimming_state_xlight_' + xlight.id + '_channel_4"\n'
    yml += '    type: int' + '\n'
    yml += '    restore_value: no' + '\n'
    yml += '    initial_value: "0"' + '\n'

    return yml
  }

  generateXLightSliderYaml(xlight, channelNo){
    let yml = '\n'
    yml += '  # dimming_slider_xlight_' + xlight.id + '_channel_' +  channelNo +'\n'
    yml += '  - platform: template' + '\n'
    yml += '    name: "dimming_slider_xlight_' + xlight.id + '_channel_' +  channelNo + '"\n'
    yml += '    id: "dimming_slider_xlight_'  + xlight.id + '_channel_' +  channelNo +'"\n'
    yml += '    step: 20' + '\n'
    yml += '    min_value: 0' + '\n'
    yml += '    max_value: 100' + '\n'
    yml += '    mode: slider' + '\n'
    yml += '    optimistic: True' + '\n'
    yml += '    on_value:' + '\n'
    yml += '      then:' + '\n'
    yml += '        - lambda:' + '\n'
    yml += '            id(dimming_state_xlight_'+ xlight.id + '_channel_' +  channelNo +') = x;' + '\n'
    yml += '        - switch.turn_on: "uart_read_or_write"' + '\n'
    yml += '        - uart.write: !lambda |-' + '\n'
    yml += '                int a = id(dimming_state_xlight_' + xlight.id + '_channel_1) * 30;' + '\n'
    yml += '                int b = id(dimming_state_xlight_' + xlight.id + '_channel_2) * 30;' + '\n'
    yml += '                int c = id(dimming_state_xlight_' + xlight.id + '_channel_3) * 30;' + '\n'
    yml += '                int d = id(dimming_state_xlight_' + xlight.id + '_channel_4) * 30;' + '\n'
    yml += '                int v[4];' + '\n'
    yml += '                std::string vs[4];' + '\n'
    yml += '                v[0] = a;' + '\n'
    yml += '                v[1] = b;' + '\n'
    yml += '                v[2] = c;' + '\n'
    yml += '                v[3] = d;' + '\n'
    yml += '                for(int i = 0;i<4;i++){' + '\n'
    yml += '                    vs[i] = "";' + '\n'
    yml += '                    int aux = v[i];' + '\n'
    yml += '                    while(aux!=0){' + '\n'
    yml += '                        vs[i] = (char)((aux%10) + '+ "'0'" +') + vs[i];' + '\n'
    yml += '                        aux /= 10;' + '\n'
    yml += '                    }' + '\n'
    yml += '                }' + '\n'
    yml += '                std::string res;' + '\n'
    yml += '                std::vector <uint8_t> resV;' + '\n';
    yml += '                res = vs[0] + "," + vs[1] + "," + vs[2] + "," + vs[3] + ",2,' + xlight.id + '\\r\\n";' + '\n'
    yml += '                for(int i = 0;i \< res.size();i++){' + '\n'
    yml += '                    resV.push_back((uint8_t) res[i]);' + '\n'
    yml += '                }' + '\n'
    yml += '                return resV;' + '\n'
    yml += '        - switch.turn_off: "uart_read_or_write"' + '\n'

    return yml
  }

  parseCsv(csv){
    // TODO: Multiple outputs / input: cate un output pe fiecare linie (cala H3)
    // this.ymlOutput=''
    // this.csvRecords.forEach(
    //   (el, i)=>{
    //     if( i > 0){
    //       this.ymlOutput += '\n' + '# ' + el[0].replace('\n', ' | ') + '\n'
    //       this.ymlOutput += '- platform: gpio' + '\n'
    //       // input
    //       let inp = el[1].split('_')
    //       // console.log('inp', inp)
    //       this.ymlOutput += '  name: "ha' + this.adamNo +'_di_' +  inp[2] + '"' +  '\n'
    //       this.ymlOutput += '  pin: ' + '\n'
    //       this.ymlOutput += '    pcf8574: ' + inp[0] + '_hub_in ' +  '\n'
    //       this.ymlOutput += '    number: ' + (inp[2] - 1) +  '\n'
    //       this.ymlOutput += '    mode: INPUT ' +  '\n'
    //       this.ymlOutput += '    inverted: True ' +  '\n'
    //       this.ymlOutput += '  on_' + el[4] + ': ' +  '\n'       // on_press, on click
    //       this.ymlOutput += '    then: ' +  '\n'
    //       this.ymlOutput += '      - switch.toggle: '+ el[3] + ' # ' + el[2] +  '\n'

    //     }
    //   }
    // )
  }

  uploadFiles(files: File[]): Subscription {
    const config = new HttpRequest('POST', this.postUrl, this.myFormData, {
      reportProgress: true
    })

    console.log('Files uploaded', files)

    return this.httpClient.request( config )
    .subscribe(event=>{
      this.httpEvent = event

      if (event instanceof HttpResponse) {
        alert('upload complete, old school alert used')
      }
    },
    error=>{
      alert('!failure beyond compare cause:' + error.toString())
    })
  }

  updateFirmware(){
    // alert('updateFirmware')
    this.generateYaml()
    this.generateBinary(this.ymlOutputText).subscribe(
      (res) => {
        if (res){
          console.log('{updateFirmware} generated binary', res)
          this.manifestLink = 'https://iotix-api.dalimedia.ro/' + res['manifest_file']
          console.log('{updateFirmware} manifestLink', this.manifestLink)
          this.showUploadButton = true
        }
      }
    )
  }

  generateBinary(yaml){
    this.loading = true
    return new Observable(subscriber => {
      const data = { yml: yaml, adamName: 'adam-ha' + this.adamNo, savePath: this.savePath }
      this.readLogs()
      return this.httpClient.post('http://iotix-api.dalimedia.ro/index.php', data).subscribe(
        (res) => {
          let result = JSON.parse(res['body'])
          subscriber.next(result)
          //compile OK
          if (result && result['out'] == 0){
            this.loading = false
          }
          // error compiling
          if (result && result['out'] > 0 ){
            this.loading = false
            alert('Something went wrong ' + result['out'])
            console.log('still loading', result['out'])
          }
        }
      )
    })
  }

  readLogs(){
    const filename = this.savePath.replace('builds/', '')
    this.httpClient.get('http://iotix-api.dalimedia.ro/logs/' + filename + '.log' ).subscribe(data => {
      this.compileLog = data.toString()
      console.log('[YamlGenerator] {readLogs} data', data)

    })
  }

  downloadGeneratedBinary(){
    this.generateYaml()
    this.generateBinary(this.ymlOutputText).subscribe(
      (res) => {
        if (res){
          console.log('[YAML Generator] {downloadGeneratedYaml} res ', res )
          console.log('File is available at http://iotix-api.dalimedia.ro/yaml/' + this.savePath)
          window.open ('http://iotix-api.dalimedia.ro/yaml/' + this.savePath + '/.pioenvs/adam-ha' + this.adamNo + '/firmware.bin')

          // const textModal = await this.modalCtrl.create({
          //   component: TextModalComponent,
          //   componentProps: { text: this.ymlOutputText }
          // })
          // textModal.present()
        }

      }
    )
  }

  async showGenerateYaml(){
    this.generateYaml()
    this.clipboard.copy(this.ymlOutputText)
    this.utils.showAlert('OK', 'YML Code copied to clipboard')
  }

  toggleAdvancedOptions(){
    if (this.advancedOptions){ this.advancedOptions = false}
    else{ this.advancedOptions = true}

  }

  checkValidation(){
    this.errors._count = 0
    for (const key in this.errors) {
      if (Object.prototype.hasOwnProperty.call(this.errors, key)) {
        if (key !== '_count'){
          this.errors[key]['error'] = false
        }
      }
    }
    if (this.general.wifi.enabled && this.general.wifi.password.length < 8 ) {
      this.errors.wifipass.error = true
      this.errors._count ++
    }
    // else{
    //   this.errors.wifipass.error = false
    // }

    if (this.general.wifi.enabled && this.general.wifi.ssid.length < 2 ) {
      this.errors.wifiSsid.error = true
      this.errors._count ++
    }
    // else{
    //   this.errors.wifiSsid.error = false
    // }
    // console.log('[YamlGenerator] {checkvalidation} errors', this.errors)
  }

  async showHelp(){
    const mod = await this.modalCtrl.create({
      component: AppHelpComponent,
      cssClass: 'w-90 h-90'
    })
    mod.present()
  }

  async showXR8Help(){
    const mod = await this.modalCtrl.create({
      component: Xr8HelpComponent,
      cssClass: 'w-90 h-90'
    })
    mod.present()
  }

  async showXswitchesAlert(){
    const mod = await this.modalCtrl.create({
      component: XswitchesAttentionComponent,
      // cssClass: 'w-90 h-90'
    })
    mod.present()
  }

  dismissLoading(){
    this.loading = false
  }

  dismissShowUpload(){
    this.showUploadButton = false
  }

  useSecretsChanged(){
    if(this.general.wifi.secrets){
      this.general.wifi.ssid = "!secret wifi_ssid"
      this.general.wifi.password = "!secret wifi_password"
      this.checkValidation()
    }
    else{
      this.general.wifi.ssid = ""
      this.general.wifi.password = ""
      this.checkValidation()
    }
  }

}
